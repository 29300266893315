import {Preferences} from "@capacitor/preferences";
import {useEffect, useState} from "react";
import {gql, useQuery} from "@apollo/client";
import ScheduleCard from "./ScheduleCard";
import formatTime from "../utils/FormatTime";
import Loader from "./Loader";

const SCHEDULE_LIST = gql`
    query schedules($pagination: PaginationArg, $filters: ScheduleFiltersInput) {
        schedules(pagination: $pagination, filters: $filters) {
            data {
                attributes {
                    subject{
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    day
                    schedule_time{
                        data {
                            attributes {
                                timeStart
                                timeEnd
                            }
                            id
                        }
                    }
                    schedule_name {
                        data {
                            id
                        }
                    }
                    classroom{
                        data {
                            attributes{
                                name
                                number
                                subject{
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                            id
                        }
                    }
                    teacher {
                        data {
                            attributes{
                                name
                            }
                            id
                        }
                    }
                }
                id
            }
        }
    }
`

const SCHEDULE_TIMES = gql`
    query scheduleTimes($pagination: PaginationArg = {}, $filters: ScheduleTimeFiltersInput, $sort: [String] = []) {
        scheduleTimes(pagination: $pagination, filters: $filters, sort: $sort) {
            data {
                attributes {
                    timeStart
                    timeEnd
                    day
                }
                id
            }
        }
    }
`

const SCHEDULES_NAME = gql`
    query scheduleNames($pagination: PaginationArg = {}, $filters: ScheduleNameFiltersInput) {
        scheduleNames(pagination: $pagination, filters: $filters, publicationState: PREVIEW) {
            data {
                attributes {
                    class {
                        data {
                            attributes {
                                letter
                                shift
                                name
                            }
                        }
                    }
                    end
                    start
                    publishedAt
                }
                id
            }
        }
    }
`


export default function PersonsList({onUpdate}) {

    const [classNumber, setClassNumber] = useState("")
    const [classLetter, setClassLetter] = useState("")
    const [personsList, setPersonsList] = useState([])
    const [schoolID, setSchoolID] = useState('')
    const [openIndex, setOpenIndex] = useState(null)
    const [scheduleData, setScheduleData] = useState([])
    const [scheduleTime, setScheduleTime] = useState([])
    const [originalScheduleOrder, setOriginalScheduleOrder] = useState([])
    const [selectedDropDown, setSelectedDropDown] = useState(null)

    // Инициализация пустых дней недели
    const emptyWeek = {
        'Monday': [],
        'Tuesday': [],
        'Wednesday': [],
        'Thursday': [],
        'Friday': [],
        'Saturday': []
    };

    const dayTranslation = {
        'Monday': 'Понедельник',
        'Tuesday': 'Вторник',
        'Wednesday': 'Среда',
        'Thursday': 'Четверг',
        'Friday': 'Пятница',
        'Saturday': 'Суббота',
        'Sunday': 'Воскресенье'
    };
    const dayOrder = {'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6};
    const deepCopy = obj => JSON.parse(JSON.stringify(obj));

    const {data} = useQuery(SCHEDULE_LIST, {
        variables: {
            filters: {
                institution: {id: {eq: schoolID}},
                schedule_name: {
                    start: {
                        lte: getCurrentDate(),
                    },
                    end: {
                        gte: getCurrentDate(),
                    },
                    publishedAt: {
                        notNull: true,
                    },
                    class: {
                        letter: {eq: classNumber},
                        name: {eq: classLetter},
                    }
                },
            },
            pagination: {
                limit: 500
            },
        },
        skip: schoolID === "" || classNumber === "" || classLetter === ""
    })

    const {data: scheduleTimesData} = useQuery(SCHEDULE_TIMES, {
        variables: {
            filters: {
                institution: {id: {eq: +schoolID}},
                callGrid: {eq: 'high_school'},
            },
            pagination: {
                limit: 2008
            },
            sort: ['id']
        },
        skip: schoolID === ""
    })

    const {data: scheduleNamesData} = useQuery(SCHEDULES_NAME, {
        variables: {
            filters: {
                institution: {id: {eq: +schoolID}},
            },
            pagination: {
                limit: 2002
            },
            publicationState: "PREVIEW"
        },
        skip: schoolID === ""
    })

    function getCurrentDate() {
        const dateObj = new Date();
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const getPersons = async () => {
        const {value} = await Preferences.get({key: 'persons'});
        return value ? JSON.parse(value) : [];
    }

    // const getSavedData = async () => {
    //     const {value} = await Preferences.get({key: 'form'});
    //     return value ? JSON.parse(value) : [];
    // };
    const getSchoolID = async () => {
        const {value} = await Preferences.get({key: 'persons'});
        const persons = value ? JSON.parse(value) : []
        if (persons.length > 0) {
            setSchoolID(persons[0].school);
        }
    };

    function processData(inputData, originalScheduleOrder) {
        const lessonsByDay = deepCopy(emptyWeek);

        inputData.forEach((schedule) => {
            const {subject, day, schedule_time, classroom, teacher, schedule_name} = schedule.attributes;
            const {id} = schedule
            if (day === null || subject === null) return;

            if (!(day in dayOrder)) return;

            if (!lessonsByDay[day]) {
                lessonsByDay[day] = [];
            }

            const timeSlot = schedule_time.data?.attributes;
            const lesson = {
                id: id,
                time: `${formatTime(timeSlot?.timeStart)} - ${formatTime(timeSlot?.timeEnd)}`,
                scheduleTime: schedule_time.data?.attributes,
                scheduleTimeId: schedule_time.data?.id,
                subject: subject.data?.attributes?.name,
                subjectId: subject.data?.id,
                room: classroom.data?.attributes?.number,
                roomId: classroom.data?.id,
                teacher: teacher.data?.attributes?.name,
                teacherId: teacher.data?.id,
                schedule_name: schedule_name.data?.id,
            };
            lessonsByDay[day].push(lesson);
        });

        // Преобразование и сортировка данных
        return Object.keys(lessonsByDay)
            .sort((a, b) => dayOrder[a] - dayOrder[b])
            .map(day => ({
                day: dayTranslation[day],
                lessons: lessonsByDay[day].sort((a, b) => {
                    const indexA = originalScheduleOrder.indexOf(a.id);
                    const indexB = originalScheduleOrder.indexOf(b.id);
                    return indexA - indexB;
                })
            }));
    }

    function processDataByDay(scheduleData, dayFilter) {
        if (scheduleData) {
            return scheduleData.filter(schedule => schedule.attributes.day === dayFilter);
        }
    }

    const weekdaySchedule = processDataByDay(scheduleTime, 'Weekdays');
    const saturdaySchedule = processDataByDay(scheduleTime, 'Saturday');

    // const deleteUser = async (key) => {
    //     console.log('key', key)
    //     await Preferences.remove({ key: key });
    // }
    const deleteUser = async (index) => {
        const keys = ['persons', 'form', 'names']
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const {value} = await Preferences.get({key});

            if (value) {
                let items = JSON.parse(value);

                if (!Array.isArray(items)) continue

                items.splice(index, 1);
                await Preferences.set({key, value: JSON.stringify(items)});
                setPersonsList(items)
            }
        }

        onUpdate();
    }

    useEffect(() => {
        if (data && data.schedules && Array.isArray(data.schedules.data)) {
            const sortedData = processData(data.schedules.data, originalScheduleOrder);
            setScheduleData(sortedData);
        }
    }, [data, originalScheduleOrder]);

    useEffect(() => {
        if (scheduleTimesData && scheduleTimesData.scheduleTimes) {
            const sortedScheduleTimes = [...scheduleTimesData.scheduleTimes.data]
                // сортируем по id
                .sort((a, b) => {
                    return a.id - b.id;
                });

            setScheduleTime(sortedScheduleTimes);
        }
        // eslint-disable-next-line
    }, [data, scheduleTimesData]);
    useEffect(() => {
        if (scheduleNamesData && scheduleNamesData.scheduleNames && Array.isArray(scheduleNamesData.scheduleNames.data)) {
            setOriginalScheduleOrder(scheduleNamesData.scheduleNames.data.map(item => item.id));
        }
    }, [scheduleNamesData]);
    useEffect(() => {
        getSchoolID().catch((e) => console.error(e))
    }, [])
    useEffect(() => {
        getPersons().then(setPersonsList);
    }, []);
    return (
        <>
            <div className='persons-list'>
                {personsList.map((person, index) => (
                    <div className='accordeon-panel' key={index}>
                        <div className="accordion-title">
                            <div className='person-row'
                                 onClick={(e) => {
                                     e.stopPropagation()
                                     setOpenIndex(openIndex === index ? null : index)
                                     setSchoolID(person.school)
                                     setClassNumber(person.classNumber)
                                     setClassLetter(person.classLetter)
                                 }}
                            >
                                <div className="person-row__content">
                                    <div className="person-row__content__left" onClick={() => {
                                        const isOpen = openIndex !== index
                                        setOpenIndex(isOpen ? index : null)
                                        document.querySelector('.container').classList.toggle('column', openIndex === null)
                                    }}>
                                        <div className="circle-name">
                                            {person?.name?.charAt(0).toUpperCase()}
                                        </div>
                                        <p>{person.name}</p>
                                        {/*<p>Region: {person.region}</p>*/}
                                        {/*<p>City: {person.city}</p>*/}
                                        {/*<p>School: {person.school}</p>*/}
                                        <p className='whitespace-nowrap'>{person.classLetter} - {person.classNumber} класс</p>
                                    </div>
                                    <div className="person-row__content__right" onClick={(e) => {
                                        e.stopPropagation()
                                        setSelectedDropDown(selectedDropDown === index ? null : index)
                                    }} onBlur={() => setSelectedDropDown(null)} tabIndex="0">
                                        <svg width="15" height="4" viewBox="0 0 15 4" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.09961" cy="2" r="1.5" fill="#868686"/>
                                            <circle cx="7.5" cy="2" r="1.5" fill="#868686"/>
                                            <circle cx="12.8994" cy="2" r="1.5" fill="#868686"/>
                                        </svg>
                                        {selectedDropDown === index && <div className="tip">
                                            {/*<div>Редактировать запись</div>*/}
                                            <div className='red' onClick={() => deleteUser(index)}>Удалить запись</div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`accordeon-content ${openIndex === index ? '' : 'hidden'}`}>
                            <ScheduleCard
                                data={scheduleData}
                                scheduleTimes={weekdaySchedule}
                                scheduleSaturdayTimes={saturdaySchedule}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

