import {gql, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import { Preferences } from '@capacitor/preferences';
import Loader from "./Loader";

const REGIONS = gql`
    query regions($filters: RegionFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        regions(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    code
                }
            }
        }
    }
`
const CITIES = gql`
    query cities($filters: CityFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        cities(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`
const INSTITUTIONS = gql`
    query institutions($filters: InstitutionFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        institutions(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`
const CLASSES = gql`
    query classes($filters: ClassFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []){
        classes(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    name
                    letter
                }
            }
        }
    }
`

export default function AddPersonForm({onBack, onCompleted}) {
    const [personName, setPersonName] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedSchool, setSelectedSchool] = useState('')
    const [selectedClassLetter, setSelectedClassLetter] = useState('')
    const [selectedClassLetterName, setSelectedClassLetterName] = useState('')
    const [selectedClassNumber, setSelectedClassNumber] = useState('')
    const [selectedClassNumberName, setSelectedClassNumberName] = useState('')
    const [nameIsEmpty, setNameIsEmpty] = useState(false)
    const [formState, setFormState] = useState({
        selectedRegion: '',
        selectedCity: '',
        selectedSchool: '',
        selectedClassNumber: '',
        selectedClassLetter: ''
    });

    const {data: regionsList, loading: regionsListLoading, error: regionsListError} = useQuery(REGIONS, {
        variables: {
            pagination: {
                limit: 10000
            },
            sort: "code"
        }
    })
    const {data: citiesList, loading: citiesListLoading, error: citiesListError} = useQuery(CITIES, {
        variables: {
            pagination: {
                limit: 10000
            },
            sort: 'name',
            filters: {
                region: {
                    id: {
                        eq: selectedRegion
                    }
                }
            }
        }
    })
    const {data: schoolsList, loading: schoolsListLoading, error: schoolsListError} = useQuery(INSTITUTIONS, {
        variables: {
            pagination: {
                limit: 10000
            },
            sort: 'name',
            filters: {
                city: {
                    id: {
                        eq: selectedCity
                    }
                }
            }
        }
    })
    const {data: classListLetters, loading: classListNumbersLoading, error: classNumbersListError} = useQuery(CLASSES, {
        variables: {
            pagination: {
                limit: 10000
            },
            sort: 'name',
            filters: {
                institution: {
                    id: {
                        eq: +selectedSchool
                    }
                }
            }
        }
    })

    const {data: classListNumbers, loading: classListLettersLoading, error: classListLettersError} = useQuery(CLASSES, {
        variables: {
            pagination: {
                limit: 10000
            },
            sort: 'letter',
            filters: {
                institution: {
                    id: {
                        eq: +selectedSchool
                    }
                },
                name: {eq: selectedClassLetter}
            }
        }
    })

    const saveName = async (name) => {
        // Получаем текущий список имен или используем пустой массив, если его нет
        const {value} = await Preferences.get({key: 'names'});
        const names = value ? JSON.parse(value) : [];

        names.push(name);

        await Preferences.set({
            key: 'names',
            value: JSON.stringify(names),
        });
    };

    const savePersonData = async (personData) => {
        // Получаем текущий список данных пользователей или используем пустой массив, если его нет
        const {value} = await Preferences.get({key: 'persons'});
        const persons = value ? JSON.parse(value) : [];

        persons.push(personData);

        await Preferences.set({
            key: 'persons',
            value: JSON.stringify(persons),
        });
    };

    // const handleCompleted = () => {
    //     if (personName !== '') {
    //         saveName(personName).catch(() => {
    //             console.error('Произошла ошибка при сохранении имени');
    //         })
    //         setNameIsEmpty(false)
    //         onCompleted();
    //     } else {
    //         setNameIsEmpty(true)
    //     }
    // }
    const handleCompleted = async () => {
        if (personName !== '') {
            // Создаем объект данных пользователя
            const personData = {
                name: personName,
                region: selectedRegion,
                city: selectedCity,
                school: selectedSchool,
                classNumber: selectedClassNumberName,
                classLetter: selectedClassLetterName,
                classID: selectedClassNumber
            };

            // Сохраняем данные пользователя
            await savePersonData(personData).catch(() => {
                console.error('Произошла ошибка при сохранении данных пользователя');
            });
            setNameIsEmpty(false);
            onCompleted();
        } else {
            setNameIsEmpty(true);
        }
    }

    const saveData = async (selectedValue, selectType) => {
        const currentFormState = {...formState};
        currentFormState[selectType] = selectedValue;
        setFormState(currentFormState);

        await Preferences.set({
            key: 'form',
            value: JSON.stringify(formState),
        });
    };
    if (regionsListLoading) {
        return <Loader />
    }

    if (regionsListError) {
        return <p>Ошибка! Проверьте подключение к интернету!</p>;
    }
    return (
        <div className='add-person-form'>
            <div className='back-btn' onClick={onBack}>&#8592; Назад</div>

            <div className="inputs-block">
                <div className={`input-wrapper ${nameIsEmpty ? 'error' : ''}`}>
                    <input
                        type="text"
                        value={personName}
                        placeholder="ФИО"
                        onBlur={(e) => {
                            const trimmedName = e.target.value.trim()
                            if (trimmedName) {
                                saveName(trimmedName).catch(() => {
                                    console.error('Произошла ошибка при сохранении имени');
                                });
                            }
                        }}
                        onChange={(e) => {
                            // Запрет ввода пробелов в начале строки
                            let value = e.target.value
                            if (value === ' ' && !personName) {
                                value = ''
                            }
                            setPersonName(value)
                            if (value.trim()) {
                                setNameIsEmpty(false)
                            }
                        }}
                    />
                </div>
                {nameIsEmpty && <div className="error">Заполните поле!</div>}
            </div>
            <div className="inputs-block">
                <div className='input-wrapper'>
                    <select name="" value={selectedRegion} onChange={(e) => {
                        setSelectedRegion(e.target.value)
                        const selectedOption = e.target.options[e.target.selectedIndex]
                        const selectedOptionText = selectedOption.text
                        saveData(selectedOptionText, 'selectedRegion').catch((e) => console.error(e))
                        setSelectedCity('')
                        setSelectedSchool('')
                        setSelectedClassLetter('')
                        setSelectedClassNumber('')
                    }}>
                        <option value="">Выберите регион</option>
                        {regionsList.regions.data.map((region, index) => (
                            <option key={index}
                                    value={region.id}>{region.attributes.code} - {region.attributes.name}</option>
                        ))}
                    </select>
                </div>
                <div className='input-wrapper'>
                    {(selectedRegion !== '' && citiesList?.cities?.data.length) &&
                        <select name="" value={selectedCity} onChange={(e) => {
                            setSelectedCity(e.target.value)
                            const selectedOption = e.target.options[e.target.selectedIndex]
                            const selectedOptionText = selectedOption.text
                            saveData(selectedOptionText, 'selectedCity').catch((e) => console.error(e))
                            setSelectedSchool('')
                            setSelectedClassLetter('')
                            setSelectedClassNumber('')
                        }}>
                            <option value="">Выберите город</option>
                            {citiesList?.cities?.data.map((item, index) => (
                                <option key={index} value={item.id}>{item.attributes.name}</option>
                            ))}
                        </select>
                    }
                </div>
            </div>

            <div className="inputs-block">
                <div className='input-wrapper'>
                    {(selectedCity !== '' && (schoolsList?.institutions?.data.length || false)) &&
                        <select name="" value={selectedSchool} onChange={(e) => {
                            setSelectedSchool(e.target.value)
                            const selectedOption = e.target.options[e.target.selectedIndex]
                            const selectedOptionText = selectedOption.text
                            saveData(selectedOptionText, 'selectedSchool').catch((e) => console.error(e))
                            setSelectedClassLetter('')
                            setSelectedClassNumber('')
                        }}>
                            <option value="">Выберите школу</option>
                            {schoolsList?.institutions?.data.map((item, index) => (
                                <option key={index} value={item.id}>{item.attributes.name}</option>
                            ))}
                        </select>
                    }
                </div>
                {(selectedSchool !== '' && (classListLetters?.classes?.data.length || false)) &&
                    <div className='input-wrapper'>
                        <select name="" value={selectedClassLetter}
                                onChange={
                                    (e) => {
                                        setSelectedClassLetter(e.target.value)
                                        const selectedOption = e.target.options[e.target.selectedIndex]
                                        const selectedOptionText = selectedOption.text
                                        setSelectedClassLetterName(selectedOptionText)
                                        saveData(selectedOptionText, 'selectedClassLetter').catch((e) => console.error(e))
                                    }
                                }>
                            <option value="">Выберите параллель</option>
                            {Array.from(
                                new Set(classListLetters.classes.data.map(item => item.attributes.name))
                            )
                                .sort((a, b) => parseInt(a) - parseInt(b))
                                .map((name, index) => (
                                <option key={index} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>
                }
                <div></div>
                {(selectedClassLetter !== '' && (classListNumbers?.classes?.data.length || false)) &&
                    <div className='input-wrapper'>
                        <select name="" value={selectedClassNumber}
                                onChange={(e) => {
                                    setSelectedClassNumber(e.target.value)
                                    const selectedOption = e.target.options[e.target.selectedIndex]
                                    const selectedOptionText = selectedOption.text
                                    setSelectedClassNumberName(selectedOptionText)
                                    saveData(selectedOptionText, 'selectedClassNumber').catch((e) => console.error(e))
                                }}>
                            <option value="">Выберите букву</option>
                            {
                                [
                                    ...new Set(
                                        classListNumbers.classes.data.map(item =>
                                            item.attributes.letter
                                                .toUpperCase()
                                                .replace(/[^А-Я]/gi, '')
                                        )
                                    )
                                ]
                                    .filter(letter => letter)
                                    .sort()
                                    .map((letter) => (
                                        <option key={letter} value={letter}>{letter}</option>
                                    ))
                            }
                        </select>
                    </div>
                }
            </div>
            {selectedClassNumber !== '' && <div className='form-btn' onClick={handleCompleted}>Продолжить</div>}
        </div>
    )
}
