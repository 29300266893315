export default function AddPersonBtn({onClick}) {
    return (
        <div className='add' onClick={onClick}>
            <span>Добавить Ребенка</span>
            <span className='icon-plus'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23.838 13.288H13.578V23.278H11.148V13.288H0.888V10.966H11.148V0.921998H13.578V10.966H23.838V13.288Z"
                        fill="black"/>
                </svg>
            </span>
        </div>
    )
}
