import {RiLoader2Fill} from 'react-icons/ri'

export default function Loader() {
    return (
        <>
            <div
                className="loader">
                <RiLoader2Fill size='60px' className='loader-icon'/>
            </div>
        </>
    )
}
