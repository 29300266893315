import React, {useState} from 'react';

const ToggleSwitch = () => {
    const [isEnabled, setIsEnabled] = useState(true);

    const toggleSwitch = () => {
        setIsEnabled(!isEnabled);
    };

    return (
        <div className='switch-container'>
            <label className="switch">
                <input type="checkbox" checked={isEnabled} onChange={toggleSwitch}/>
                <span className="slider"></span>
                <span className="label"></span>
            </label>
            <span>Push уведомления</span>
        </div>
    );
};

export default ToggleSwitch;
