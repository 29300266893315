import {useEffect, useState} from "react"

import formatTime from "../utils/FormatTime";
import Loader from "./Loader";


export default function ScheduleCard({data, scheduleTimes, scheduleSaturdayTimes, scheduleName, loading}) {
    function getWeekDates(date = new Date()) {
        const dayOfWeek = date.getDay();
        const diffToMonday = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

        let weekDates = Array.from({length: 6}, (_, i) => {
            let tempDate = new Date(date);
            tempDate.setDate(diffToMonday + i);
            return tempDate;
        });

        return weekDates;
    }

    const [weekDates, setWeekDates] = useState(getWeekDates());
    const currentDay = new Date().toLocaleDateString('ru-RU', {weekday: 'long'});

    const formatDate = (date) => {
        return date.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'});
    }

    useEffect(() => {
        setWeekDates(getWeekDates());
    }, []);
    return (
        <>
            {loading && <Loader/>}
            {data.map((daySchedule, dayIndex) => {
                const scheduleDate = formatDate(weekDates[dayIndex])
                return (
                    <div className="schedule w-[460px] shadow-lg rounded overflow-hidden"
                         key={daySchedule.date + String(dayIndex)}>
                        {!loading && <div className="day-schedule">
                            <div
                                className={`day-schedule__header flex items-center justify-between ${daySchedule.day.toLowerCase() === currentDay.toLowerCase() ? 'current' : ''}`}>
                                <div className="font-bold">{daySchedule.day}</div>
                                <div>{scheduleDate}</div>
                            </div>
                            <div className="lessons px-4">
                                {(daySchedule.day === 'Суббота' ? scheduleSaturdayTimes : scheduleTimes).map((time, timeIndex) => {
                                    const lesson = daySchedule.lessons.filter(lesson => {
                                        return lesson.scheduleTimeId === time.id
                                    });
                                    // const timeKey = `${daySchedule.day}-${formatTime(time.attributes.timeStart)}`;

                                    return (
                                        <>
                                            <div key={`${dayIndex}-${timeIndex}`}
                                                 className="lesson flex items-center lessons--row">
                                                <div className="lesson__time">
                                                    <div>{`${formatTime(time.attributes.timeStart)}`}</div>
                                                    <div>{`${formatTime(time.attributes.timeEnd)}`}</div>
                                                </div>
                                                {lesson && lesson.length > 0 ? (
                                                    <div className=""> {
                                                        lesson.map((l) => {
                                                            return (
                                                                <>
                                                                    <div className="lesson__content">
                                                                        <div className="">
                                                                            <div
                                                                                className="lesson__subject">{l.subject}</div>
                                                                            {/*<div className="text-sm">{formatNameToInitials(l.teacher)}</div>*/}
                                                                            {/*<div className="w-auto mx-2">{l.room}</div>*/}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                ) : (
                                                    <div className='w-full min-h-[100px]'></div>
                                                )}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                )
            })}
        </>
    )

}
