import {useEffect, useRef, useState} from "react"
import Welcome from "../components/Welcome"
import AddPersonForm from "../components/AddPersonForm"
import SideMenu from "../components/SideMenu"
import PersonList from "../components/PersonsList"
import {Preferences} from "@capacitor/preferences";
import AddPersonBtn from "../components/AddPersonBtn";
import NewsList from "../components/NewsList";
import {gql, useQuery} from "@apollo/client";

const MESSAGES = gql`
    query messages($filters: MessageFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        messages(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    publishedAt
                    title
                    description
                }
            }
        }
    }
`
function getMostRecentMessageDate(messages) {
    return messages.reduce((mostRecentTimestamp, currentMessage) => {
        const currentTimestamp = new Date(currentMessage.attributes.publishedAt).getTime();
        return currentTimestamp > mostRecentTimestamp ? currentTimestamp : mostRecentTimestamp;
    }, 0); // Инициализируем самой ранней возможной временной меткой
}
function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
export default function HomePage() {
    const [isAddingPerson, setIsAddingPerson] = useState(false)
    const [isMainPage, setIsMainPage] = useState(true)
    const [menuOpen, setMenuOpen] = useState(false)
    const [showPersonList, setShowPersonList] = useState(false)
    const [personList, setPersonList] = useState([])
    const [showNewsList, setShowNewsList] = useState(false)
    const [listKey, setListKey] = useState(Math.random())
    const [unreadCount, setUnreadCount] = useState(0)
    const [schools, setSchools] = useState()
    const [personListEmpty, setPersonListEmpty] = useState(true)
    const {data, refetch} =useQuery(MESSAGES, {
        variables: {
            filters: {
                institution: {
                    id: {
                        in: schools
                    }
                }
            },
            sort: ['publishedAt'],
            pagination: {
                limit: 10000
            }
        },
        skip: schools === 'undefined'
    })

    const handleAddPersonClick = () => {
        setIsAddingPerson(false)
        setIsAddingPerson(true);
        setIsMainPage(false)
    }

    const handleBack = () => {
        setIsAddingPerson(false)
        setShowPersonList(false)
        setShowNewsList(false)
        setIsMainPage(true)
    }

    const showList = () => {
        getPersons().then(setPersonList)
        setIsAddingPerson(false)
        setShowPersonList(true)
        setPersonListEmpty(false)
        setListKey(Math.random())
    }

    const getPersons = async () => {
        const {value} = await Preferences.get({key: 'persons'});
        return value ? JSON.parse(value) : [];
    }
    useInterval(async () => {
        if(schools) {
            await refetch();
        }
    }, 10000)
    useEffect(() => {
        (async () => {
            const personsData = await Preferences.get({ key: 'persons' })
            const persons = JSON.parse(personsData.value)
            if(persons) {
                setPersonListEmpty(false)
                setSchools(persons.map(person => +person.school))
            }
        })();
    }, [])
    useEffect(() => {
        if (data && Array.isArray(data.messages.data)) {
            const mostRecentTimestamp = getMostRecentMessageDate(data.messages.data);
            const storedTimestamp = parseInt(localStorage.getItem('mostRecentDate'), 10) || 0;
            console.log('storedTimestamp', storedTimestamp)

            // Add the following section
            const unreadMessages = data.messages.data.filter((message) => {
                const messageTimestamp = new Date(message.attributes.publishedAt).getTime();
                return messageTimestamp > storedTimestamp;
            });
            setUnreadCount(unreadMessages.length);
            // End of section to add

            if (!localStorage.getItem('mostRecentDate') ||
                mostRecentTimestamp / 1000 > storedTimestamp / 1000) {
                // localStorage.setItem('mostRecentDate', (mostRecentTimestamp / 1000).toString());
            }
        }
    }, [data]);

    useEffect(() => {
        // Загружаем данные пользователей
        getPersons().then(setPersonList);
    }, []);

    return (
        <>
            <div className={`main ${isMainPage && !showNewsList ? 'with-bg' : ''}`}>
                <div className="container">
                    <div className="header">
                        <div className="menu-btn" onClick={() => setMenuOpen(true)}>
                            <svg width="29" height="22" viewBox="0 0 29 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 1H29" stroke="black" strokeWidth="2"/>
                                <path d="M0 11H29" stroke="black" strokeWidth="2"/>
                                <path d="M0 11H28" stroke="black" strokeWidth="2"/>
                                <path d="M0 21H29" stroke="black" strokeWidth="2"/>
                            </svg>
                        </div>
                        {!personListEmpty && <div className='bell' onClick={() => setShowNewsList(true)}>
                            <span className='bell-counter'>
                                {unreadCount > 0 && `${unreadCount}`}
                            </span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.9998 25.3334C20 26.0062 19.7459 26.6542 19.2885 27.1475C18.831 27.6408 18.204 27.943 17.5331 27.9934L17.3331 28.0001H14.6665C13.9937 28.0003 13.3457 27.7462 12.8524 27.2888C12.3591 26.8313 12.0569 26.2043 12.0065 25.5334L11.9998 25.3334H19.9998ZM15.9998 2.66675C18.4198 2.66671 20.7452 3.60661 22.4854 5.28818C24.2257 6.96976 25.2448 9.26153 25.3278 11.6801L25.3331 12.0001V17.0187L27.7625 21.8774C27.8685 22.0894 27.9215 22.3239 27.9171 22.5609C27.9127 22.7979 27.8508 23.0303 27.7369 23.2382C27.623 23.4461 27.4605 23.6232 27.2631 23.7545C27.0658 23.8858 26.8395 23.9673 26.6038 23.9921L26.4505 24.0001H5.54913C5.31202 24.0002 5.07842 23.9427 4.86836 23.8327C4.6583 23.7227 4.47804 23.5635 4.34302 23.3685C4.208 23.1736 4.12226 22.9489 4.09314 22.7136C4.06401 22.4782 4.09238 22.2394 4.1758 22.0174L4.23713 21.8774L6.66646 17.0187V12.0001C6.66646 9.52473 7.6498 7.15076 9.40013 5.40042C11.1505 3.65008 13.5244 2.66675 15.9998 2.66675Z"
                                    fill="currentColor"/>
                            </svg>
                        </div>}
                    </div>
                    {menuOpen && <SideMenu onClose={() => setMenuOpen(false)}/>}
                    {personList.length > 0 &&
                        <PersonList key={listKey} onUpdate={() => setListKey(Math.random())} onBack={handleBack}/>}
                    {(!isAddingPerson && !showPersonList && !personList.length) && <Welcome/>}
                    {isAddingPerson && <AddPersonForm onBack={handleBack} onCompleted={showList}/>}
                    {!isAddingPerson && <AddPersonBtn onClick={handleAddPersonClick}/>}

                    {showNewsList && <NewsList onBack={handleBack} setUnreadCount={setUnreadCount} />}
                </div>
            </div>
        </>
    )
}
