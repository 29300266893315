import {gql, useQuery} from "@apollo/client";
import {useEffect, useRef, useState} from "react";
import formatDateTime from "../utils/FormatDateTime";
import { Preferences } from '@capacitor/preferences';
import Loader from "./Loader";

const MESSAGES = gql`
    query messages($filters: MessageFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        messages(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    publishedAt
                    title
                    description
                }
            }
        }
    }
`
function getMostRecentMessageDate(messages) {
    return messages.reduce((mostRecentTimestamp, currentMessage) => {
        const currentTimestamp = new Date(currentMessage.attributes.publishedAt).getTime();
        return currentTimestamp > mostRecentTimestamp ? currentTimestamp : mostRecentTimestamp;
    }, 0); // Инициализируем самой ранней возможной временной меткой
}

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default function NewsList({onBack, setUnreadCount}) {
    const [newsListData, setNewsListData] = useState([])
    const [schools, setSchools] = useState()
    const {data, refetch} =useQuery(MESSAGES, {
        variables: {
            filters: {
                institution: {
                    id: {
                        in: schools
                    }
                }
            },
            sort: ['publishedAt:desc'],
            pagination: {
                limit: 10000
            }
        }
    })
    const handleBackClick = () => {
        const mostRecentDate = getMostRecentMessageDate(newsListData);
        localStorage.setItem('mostRecentDate', mostRecentDate.toString());
        onBack();
    };
    useInterval(async () => {
        await refetch();
    }, 1000)
    useEffect(() => {
        (async () => {
            const personsData = await Preferences.get({ key: 'persons' })
            const persons = JSON.parse(personsData.value)
            setSchools(persons.map(person => +person.school))
        })();
    }, [])
    useEffect(() => {
        if (data && Array.isArray(data.messages.data)) {
            const reversedData = [...data.messages.data];
            const storedTimestamp = parseInt(localStorage.getItem('mostRecentDate'), 10) || 0;

            setNewsListData(reversedData.map(message => ({
                ...message,
                isUnread: new Date(message.attributes.publishedAt).getTime() > storedTimestamp,
                markedAsRead: false // Добавляем флаг
            })));

            const unreadCount = reversedData.reduce((count, message) => {
                const messageTimestamp = new Date(message.attributes.publishedAt).getTime();
                return count + (messageTimestamp > storedTimestamp ? 1 : 0);
            }, 0);
            setUnreadCount(unreadCount);
        }
    }, [data, setUnreadCount]);


    const markMessageAsRead = (messageId) => {
        const updatedNewsListData = newsListData.map(message => {
            if (message.id === messageId) {
                return { ...message, isUnread: false, markedAsRead: true }; // Обновляем флаги
            }
            console.log('message', new Date(message.attributes.publishedAt).getTime() > localStorage.getItem('mostRecentDate'))
            return message;
        });

        setNewsListData(updatedNewsListData);

        const newUnreadCount = updatedNewsListData.reduce((count, message) => {
            return count + (message.isUnread ? 1 : 0);
        }, 0);
        setUnreadCount(newUnreadCount);

    };


    if (!data) {
        return <Loader />;
    }
    return (
        <div className='news-page'>
            <div className='back-btn' onClick={handleBackClick}>&#8592; Назад</div>
            {newsListData?.map((item) => (
                <div key={item.id} className={`news-item ${item.isUnread ? 'unread' : ''}`}>
                    <div className="news-item__content">
                        <div className='news-item__title'>{item.attributes.title}</div>
                        <div className='news-item__date'>{formatDateTime(item.attributes.publishedAt)}</div>
                        <div className='news-item__description'>{item.attributes.description}</div>
                        {item.isUnread && !item.markedAsRead && (
                            <div className='readed' onClick={() => markMessageAsRead(item.id)}>Отметить как прочитанное</div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}
