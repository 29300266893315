import packageJson from '../../package.json';
import ToggleSwitch from "./ToggleSwitch";

export default function SideMenu({onClose}) {
    return (
        <div className='menu-overlay' onClick={onClose}>
            <div className='side-menu' onClick={(e) => e.stopPropagation()}>
                <div className="close" onClick={onClose}>
                    <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 21.5059L24.5061 0.999763" stroke="black" strokeWidth="2"/>
                        <path d="M4 1L24.5061 21.5061" stroke="black" strokeWidth="2"/>
                    </svg>
                </div>

                <div className="menu-content">
                    <div className="menu-content__top">
                        <ToggleSwitch/>
                    </div>
                    <div className="menu-content__bottom">
                        {/*<a href='#'>ТехПоддержка</a>*/}
                        <div className='app-version'>V {packageJson.version}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
